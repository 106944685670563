import { Home, Users, Case, Payments, Apply, Messages, Staff, TalentPool, Jobs, TrackApplication, Conversation, Wallet, Payroll, Employee, Team, Setting, Pyramid } from './icons';
export default [
  [
    {
      to: '/dashboard',
      icon: Home,
      label: 'Home',
    },
  ],
  'RECRUITMENT',
  [
    {
      to: '/talentpool',
      label: 'Talent Pool',
      icon: Users,
    },
    {
      to: '/jobs',
      label: 'Jobs',
      icon: Case,
    },
    {
      to: '/tracking',
      label: 'Track Applications',
      icon: Apply,
    },
    {
      to: '/subscriptions',
      label: 'Subscriptions',
      icon: Payments,
    },
    {
      to: '/conversations',
      label: 'Conversations',
      icon: Messages,
    },
  ],
  // 'FINANCES',
  // [
  //   {
  //     to: '/wallet',
  //     label: 'Wallet',
  //     icon: Wallet,
  //   },
  //   {
  //     to: '/payroll',
  //     label: 'Payroll ',
  //     icon: Payroll,
  //   },
  //   {
  //     to: '/employees',
  //     label: 'Employees',
  //     icon: Employee,
  //   },
  // ],
  'COMPANY',
  [
    {
      to: '/team',
      label: 'Team',
      icon: Staff,
    },
    {
      to: '/settings',
      label: 'Settings',
      icon: Setting,
    },
  ],
];
