<template>
  <ul class="">
    <aside-menu-item v-for="(item, index) in menu" :key="index" :item="item" :is-submenu-list="isSubmenuList" @menu-click="menuClick" />
  </ul>
</template>

<script>
import AsideMenuItem from '@/components/partials/AsideMenuItem.vue';
export default {
  props: {
    isSubmenuList: { type: Boolean, default: false },
    menu: { type: Array, default: () => [] },
  },
  components: {
    AsideMenuItem,
  },
  emit: ['menu-click'],
  methods: {
    menuClick(event, item) {
      this.$emit('menu-click', event, item);
    },
  },
};
</script>
