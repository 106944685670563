<template>
  <div class="font-GTWalsheimPro" id="main">
    <router-view />
    <broadcasting ref="broadcast" />
  </div>
</template>

<script setup>
import Broadcasting from './components/Broadcasting.vue';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { captureRedirectUrl } from './utils/helper';

const broadcast = ref(null);
const store = useStore();

onMounted(async () => {
  try {
    captureRedirectUrl(store);
    if (store.getters['auth/accessToken'] !== null) {
      //user token is present, not get user to see if email has been verified
      const user = store.getters['auth/userDetails'];
      if (user?.emailIsVerified){
        broadcast.value.connect();
        broadcast.value.bindChannels();
        // this.$refs.broadcast.disconnect()
      }
      if (store.getters['global/getCountries']?.length < 1) {
        store.dispatch('global/getCountries');
      }
    }

  } catch (error) {
    process.env.NODE_ENV === 'development' ? console.warn(error) : '';
  }
  window.addEventListener(
    'keydown',
    event => {
      if (event.defaultPrevented) return; // Do nothing if the event was already processed
      const aForm = document.querySelector('form');
      if (event.key === 'Enter' && aForm) {
        const btn = aForm.querySelector('button');
        if (btn) {
          btn.click();
          // Cancel the default action to avoid it being handled twice
          event.preventDefault();
        }
      }
    },
    true,
  );
});
</script>

<style></style>
