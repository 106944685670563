import { useToast } from 'vue-toastification';

const toast = useToast();

export const captureRedirectUrl = async ({ commit }) => {
  const url = window.location.href.split('?')[1];
  if (!url) return;
  const path = url.split('=')[1]?.replace(process.env.VUE_APP_BASE_URL, '');
  commit('global/setRedirectUrl', path);
};

export const alert = (message, type = 'info', timeout = 3000) => {
  switch (type) {
    case 'info':
      toast.info(message, {
        timeout,
        hideProgressBar: true,
      });
      break;
    case 'success':
      toast.success(message, {
        timeout,
        hideProgressBar: true,
      });
      break;
    case 'error':
      toast.error(message, {
        timeout,
        hideProgressBar: true,
      });
      break;

    default:
      toast.info(message, {
        timeout,
        hideProgressBar: true,
      });
      break;
  }
};

export const role = (job) => job.role_title || job.job.title_name;

export const createJobLink = (job) => {
  const company_name = job?.postedBy?.name?.toLowerCase().replaceAll(' ', '-');
  const title = role(job)?.toLowerCase().replaceAll(' ', '-');
  const job_link = `/${company_name}/${job.fid}/${title.replaceAll('/', '-')}`;
  return job_link;
};

export const saveResumeUrl = ({ commit }, url) => {
  const paths = url?.split('/');
  if (!paths) {
    alert('no resume to display', 'info');
    return;
  }
  const key = paths[paths.length - 1];
  commit('global/setResumeUrl', { key: key.split('?')[0], url });
  const href = `/file/${key}`;
  const a = document.createElement('a');
  a.setAttribute('href', href);
  a.setAttribute('target', '_blank');
  a.click();
};

export const sleep = async (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
};
export const checkStatus = (staus) => {
  return staus === true;
};

export const formatNumber = (amount) => {
  return parseFloat(amount)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const formatString = (str) => {
  if (str) {
    return str.split('_').join(' ');
  } else {
    return '';
  }
};

export const getCountry = (country) => {
  try {
    const values = Object.values(country);
    return values[2];
  } catch (error) {}
};

export const errorMessage = (error) => {
  if (error.response) {
    return error.response.data.message;
  }
  if (error.request) {
    return error.request.message;
  }
  return error.message;
};

export const badgeColor = (status) => {
  // if (!['Pending','Reviewed','Interview','Hired','Rejected'].includes(status)) throw error('...')
  return (
    {
      pending: 'bg-[#F6D44D1A] text-[#F6D44D]',
      reviewed: 'bg-[#18D8B61A] text-[#18D8B6]',
      interview: 'bg-[#1EAEFF1A] text-[#1EAEFF]',
      hired: 'bg-[#1EB12D1A] text-[#1EB12D]',
      rejected: 'bg-[#FE06331A] text-[#FE0633]',
    }[status.toLowerCase()] || 'bg-brand-primary text-white'
  );
};
