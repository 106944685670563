import { createApp } from 'vue';
import App from './App.vue';
// import { darkModeKey, styleKey } from '@/config.js'
import './index.css';
import router from './router';
import store from './store';
import Toast from 'vue-toastification';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';
import VOtpInput from 'vue3-otp-input';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import VueTimepicker from 'vue3-timepicker';
import 'vue3-timepicker/dist/VueTimepicker.css';
import vueKanban from 'vue-kanban';
import VueSocialSharing from 'vue-social-sharing';
import VueClipboard from 'vue3-clipboard';
import VueCountdown from '@chenfengyuan/vue-countdown';
import ButtonLoader from '@/components/ButtonLoader.vue';
import VuePapaParse from 'vue-papa-parse'

createApp(App)
  .use(store)
  .use(router)
  .use(Toast)
  .use(vueKanban)
  .use(VueSocialSharing)
  .use(VuePapaParse)
  .use(VueClipboard, { autoSetContainer: true, appendToBody: true })
  .component('v-otp-input', VOtpInput)
  .component('QuillEditor', QuillEditor)
  .component('Datepicker', VueDatePicker)
  .component('VueTimepicker', VueTimepicker)
  .component(VueCountdown.name, VueCountdown)
  .component('btn-loader', ButtonLoader)
  .mount('#app');
