<template>
  <main class="dashboard-layout">
    <section class="flex">
      <!-- NAVIGATION AND HEADER -->
      <div>
        <aside-menu :menu="getMenu" />
        <nav-bar />
      </div>

      <!-- MAIN CONTENT -->
      <div class="dashboard-layout-main w-full p-4 lg:px-9 lg:pt-8 bg-[#FAFAFA]">
        <div class="lg:hidden p-3 flex flex-col">
          <h5 class="text-2xl">{{ currentRouteName }}</h5>
          <p class="text-opacity-70 text-brand-black">{{ currentRouteSubText }}</p>
        </div>
        <router-view />
      </div>

      <overlay v-show="isAsideLgActive" z-index="z-30" @overlay-click="overlayClick" />
    </section>
  </main>
</template>

<script>
import AsideMenu from "@/components/partials/AsideMenu.vue";
import NavBar from "@/components/partials/NavBar.vue";
import adminMenu from "@/adminMenu.js";
import employerMenu from "@/menu.js";
import Overlay from "@/components/Overlay.vue";

export default {
  components: {
    AsideMenu,
    NavBar,
    Overlay,
  },
  data() {
    return {};
  },
  computed: {
    getMenuType() {
      return this.$route.meta.menu || "employer";
    },
    getMenu() {
      return this.getMenuType === "admin" ? adminMenu : employerMenu;
    },
    currentRouteName() {
      return this.$route.meta.title || "";
    },
    currentRouteSubText() {
      return this.$route.meta.text;
    },
    isAsideLgActive() {
      return this.$store.state.isAsideLgActive;
    },
    userDetails() {
      return this.$store.state.state.auth.userDetails;
    },
  },
  methods: {
    overlayClick() {
      this.$store.dispatch("asideLgToggle", false);
    },
  },
  // created() {
  //   this.$store.commit('user', {
  //     name: 'John Doe',
  //     email: 'john@example.com',
  //     avatar: 'https://avatars.dicebear.com/api/avataaars/example.svg?options[top][]=shortHair&options[accessoriesChance]=93',
  //   });
  // },
};
</script>

<style scoped>
.dashboard-layout {
  @apply pt-24 xl:pl-60;
  @apply w-screen transition-position lg:w-auto;
}
.dashboard-layout-main {
  /* height: calc(100vh - 120px);*/
}
</style>
