<template>
  <nav-bar-item ref="root" type="block" :has-divider="hasDivider" :active="isDropdownActive" dropdown class="dropdown" @click="toggle">
    <a class="flex items-center py-3" :class="[navBarMenuListUpperLabelStyle]">
      <slot />
      <icon :path="toggleDropdownIcon" class="hidden lg:inline-flex transition-colors" />
    </a>
    <div
      class="text-sm lg:border-b-0 lg:border-gray-200 lg:bg-white lg:absolute lg:top-full lg:left-0 lg:min-w-full lg:z-20 lg:shadow-md lg:rounded-b lg:dark:bg-gray-800 dark:border-gray-700"
      :class="[lightBorderStyle, { 'lg:hidden': !isDropdownActive }]"
    >
      <slot name="dropdown" />
    </div>
  </nav-bar-item>
</template>

<script>
import { mdiChevronUp, mdiChevronDown } from '@mdi/js';
import NavBarItem from '@/components/NavBarItem.vue';
import Icon from '@/components/Icon.vue';

export default {
  props: {
    hasDivider: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    NavBarItem,
    Icon,
  },
  data() {
    return {
      isDropdownActive: false,
      root: null,
    };
  },
  computed: {
    lightBorderStyle() {
      return this.$store.state.lightBorderStyle;
    },
    navBarMenuListUpperLabelStyle() {
      return this.$store.state.navBarMenuListUpperLabelStyle;
    },
    toggleDropdownIcon() {
      return this.isDropdownActive ? mdiChevronUp : mdiChevronDown;
    },
  },
  methods: {
    toggle() {
      this.isDropdownActive = !this.isDropdownActive;
    },
    // forceClose(event) {
    //   if (!this.root.$el.contains(event.target)) {
    //     this.isDropdownActive = false;
    //   }
    // },
  },
  mounted() {
    window.addEventListener('click', this.forceClose);
  },
  BeforeUnmount() {
    window.removeEventListener('click', this.forceClose);
  },
};
</script>
