<template>
  <aside
    id="aside"
    class="w-60 fixed top-0 z-40 h-screen transition-position lg:left-0 bg-brand-black"
    :class="[asideStyle, isAsideMobileExpanded ? 'left-0' : '-left-60', isAsideLgActive ? 'block' : 'lg:hidden xl:block']"
  >
    <div class="flex flex-row w-full flex-1 items-center mb-6">
      <div class="px-3 my-[2rem]">
        <div class="w-[158px]">
          <img src="@/assets/images/logo-new.svg" class="mb-2" alt="Logo" />
          <p class="text-right uppercase text-light-green font-semibold text-[10px]">employers</p>
        </div>
      </div>
    </div>
    <div>
      <template v-for="(menuGroup, index) in menu">
        <p v-if="typeof menuGroup === 'string'" :key="`a-${index}`" class="p-4 uppercase text-light-grey text-[15px] leading-[150%]" :class="[asideMenuLabelStyle]">
          {{ menuGroup }}
        </p>
        <aside-menu-list v-else :key="`b-${index}`" :menu="menuGroup" @menu-click="menuClick" />
      </template>
    </div>
  </aside>
</template>

<script>
// import { computed } from "vue";
// import { mdiMenu } from "@mdi/js";
import AsideMenuList from '@/components/partials/AsideMenuList.vue';
// import NavBarItem from "@/components/NavBarItem.vue";
// import Icon from "@/components/Icon.vue";

export default {
  props: {
    menu: { type: Array, default: () => [] },
  },
  components: {
    AsideMenuList,
  },
  data() {
    return {};
  },
  computed: {
    asideStyle() {
      return this.$store.state.asideStyle;
    },
    // asideBrandStyle () {
    //   return this.$store.state.asideBrandStyle
    // },
    // asideMenuCloseLgStyle () {
    //   return this.$store.state.asideMenuCloseLgStyle
    // },
    asideMenuLabelStyle() {
      return this.$store.state.asideMenuLabelStyle;
    },
    isFullScreen() {
      return this.$store.state.isFullScreen;
    },
    isAsideMobileExpanded() {
      return this.$store.state.isAsideMobileExpanded;
    },
    isAsideLgActive: {
      get() {
        return this.$store.state.isAsideLgActive;
      },
      set(value) {
        this.$store.dispatch('asideLgToggle', value);
      },
    },
  },
  methods: {
    asideLgClose() {
      this.$store.dispatch('asideLgToggle', false);
    },
    menuClick(event, item) {
      this.isAsideLgActive = !this.$store.getters.isAsideLgActive;
      document.getElementById('overlay_div').querySelector('div').click();
    },
  },
};
</script>
