<template>
  <div class="flex items-center flex-col justify-center overflow-hidden fixed inset-0" :class="zIndex" id="overlay_div">
    <transition
      enter-active-class="transition duration-150 ease-in"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div class="absolute inset-0 bg-gray-900 opacity-90 backdrop-blur-3xl backdrop-filter" :class="[overlayStyle]" @click="overlayClick" />
    </transition>
    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="animate-fadeOut"
    >
      <slot />
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    zIndex: {
      type: String,
      default: 'z-40',
    },
  },
  emit: ['overlay-click'],

  methods: {
    overlayClick(event) {
      this.$emit('overlay-click', event);
    },
  },
  computed: {
    overlayStyle() {
      return this.$store.state.overlayStyle;
    },
  },
};
</script>
