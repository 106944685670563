import { Dashboard, TalentPool, Jobs, TrackApplication, Employee, Payment, Admin } from './icons';
export default [
  [
    {
      to: '/admin/dashboard',
      icon: Dashboard,
      label: 'Dashboard',
    },
    {
      to: '/admin/jobs',
      label: 'Jobs',
      icon: Jobs,
    },
    {
      to: '/admin/applications',
      label: 'Applications',
      icon: TrackApplication,
    },
    {
      to: '/admin/talentpool',
      label: 'Talent Pool',
      icon: TalentPool,
    },

    {
      to: '/admin/companies',
      label: 'Companies',
      icon: Employee,
    },
    {
      to: '/admin/payments',
      label: 'Payment',
      icon: Payment,
    },
    {
      to: '/admin/room',
      label: 'Admin Room ',
      icon: Admin,
    },
  ],
];
