<template></template>

<script>
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

export default {
  name: 'laravel-echo',
  data() {
    return {
      echo: null,
    };
  },
  computed: {
    token: {
      cache: false,
      get() {
        return this.$store.state.auth.token;
      },
    },
    currentUser: {
      cache: false,
      get() {
        return this.$store.state.auth.userDetails;
      },
    },
    notifications: {
      cache: false,
      get() {
        return this.$store.getters.notifications.reverse();
      },
    },
    isConnected: {
      cache: false,
      get() {
        return this.echo && this.echo.connector.pusher.connection.connection !== null;
      },
    },
  },
  watch: {
    currentUser: {
      handler(currentUser) {
        currentUser !== null ? this.connect() : this.disconnect();
      },
    },
    isConnected: {
      handler(isConnected) {
        this.$emit('broadcasting:status', isConnected);
      },
    },
  },
  methods: {
    async setConversationList() {
      await this.$store.dispatch('conversation/getAllConversations');
    },
    connect() {
      this.setConversationList();
      if (!this.echo) {
        this.echo = new Echo({
          broadcaster: 'pusher',
          key: process.env.VUE_APP_PUSHER_APP_KEY,
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          encrypted: true,
          authEndpoint: `${process.env.VUE_APP_API_URL}/broadcasting/auth`,
          auth: {
            headers: {
              Authorization: null,
            },
          },
        });
        this.echo.connector.pusher.connection.bind('connected', (event) => this.connect(event));
        this.echo.connector.pusher.connection.bind('disconnected', () => this.disconnect());
      }

      this.echo.connector.options.auth.headers.Authorization = 'Bearer ' + this.token;
      this.echo.connector.pusher.connect();
    },
    bindChannels() {
      let vm = this;
      this.echo
        .private('conversation.' + this.currentUser.employer.participant_identifier)
        .listen('ConversationCreated', (payload) => {
          vm.$store.commit('conversation/addConversation', payload);
        })
        .listen('ConversationMessageCreated', (payload) => {
          vm.$store.commit('conversation/addMessage', payload.message);
          vm.$store.commit('conversation/addConversation', payload.conversation);
        });
    },
    disconnect() {
      if (!this.echo) return;
      this.echo.disconnect();
    },
  },
};
</script>

<style></style>
