import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import routes from './routes';

const gotoPage = (intendedTo, path = '/login') => ({
  path,
  // save the location we were at to come back later
  query: { redirect: intendedTo.fullPath },
});

const displayOverlay = (state) => {
  store.dispatch('asideMobileToggle', state);
  store.dispatch('asideLgToggle', state);
};

const handleRoute = async (route) => {
  // this route requires auth
  if (route.meta.requiresAuth === true) {
    //check if access token is stored
    const token = store.getters['auth/accessToken'];
    if (token) {
      try {
        /* Collapse mobile aside menu on route change */
        displayOverlay(false);
        if (['EmployerRegisterVerify', 'ProfileInformation'].includes(route.name)) return true;
        //verify token validity
        displayOverlay(true);
        await store.dispatch('auth/getUser', { forceReload: true, loadAppData: true });
        displayOverlay(false);
      } catch (error) {
        displayOverlay(false);
        if (error.response?.status === 401) {
          // unathorized, clear access token and redirect to login page.
          store.commit('setToken', null);
          return gotoPage(route);
        }
        if (error.response?.status === 403) {
          // account suspended, redirect to dashboard.
          return route.name === 'EmployerDasboard' ? true : gotoPage(route, '/dashboard');
        }
        if (error.response?.status === 423) {
          // account lokced, redirect to change password.
          return route.name === 'EmployerSetting' ? true : gotoPage({ fullPath: '/dashboard' }, '/settings');
        }
        //return false; //some other error, stop navigation
        return gotoPage(route);
      }
    } else {
      // if not, redirect to login page.
      return gotoPage(route);
    }
  }
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
});

router.beforeResolve(handleRoute);

export default router;
