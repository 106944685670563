import { axiosInstance } from '@/utils/axios';
class AuthService {
  async login(user) {
    return await axiosInstance.post('auth/login', user);
  }
  async register(user) {
    return await axiosInstance.post('auth/register', user);
  }
  async requestOtp(payload) {
    return await axiosInstance.post('auth/verify-email/request-otp', payload);
  }
  async verifyOtp(payload) {
    return await axiosInstance.post('auth/verify-email/verify-otp', payload);
  }
  async forgetPasswordRequestOtp(user) {
    return await axiosInstance.post('auth/forgot-password/request-otp', user);
  }
  async forgetPasswordVerifyOtp(details) {
    return await axiosInstance.post('auth/forgot-password/verify-otp', details);
  }
  async forgetPasswordReset(details) {
    return await axiosInstance.post('auth/forgot-password/reset', details);
  }
  async resetPassword(details) {
    return await axiosInstance.post('employer/profile/security/password', details);
  }
  async getUser() {
    return await axiosInstance.get('employer/profile');
  }
  async getEmployee() {
    return await axiosInstance.get('employer/member');
  }
  async updateUserProfile(details) {
    return await axiosInstance.post('employer/profile', details);
  }
  async updateEmployeeProfile(details) {
    return await axiosInstance.put('employer/member', details);
  }
  async updateUserAvatar(details) {
    return await axiosInstance.post('employer/member/picture', details);
  }
  async updateEmployerAvatar(details) {
    return await axiosInstance.post('employer/profile/logo', details);
  }
  async updateCompanyBanner(details) {
    return await axiosInstance.post('employer/profile/banner', details);
  }
  async getTeamMembers() {
    return await axiosInstance.get('employer/teams');
  }
  async inviteTeamMember(details) {
    return await axiosInstance.post('employer/teams/invite', details);
  }
  async revokeTeamMember(invite) {
    return await axiosInstance.delete(`employer/teams/${invite}/revoke`);
  }
  async resendTeamInvite(details) {
    return await axiosInstance.post(`employer/teams/resend-invite`, details);
  }
  async verifyTeamInvite(uuid) {
    return await axiosInstance.get(`auth/invite-user/verify?uuid=${uuid}`);
  }
  async teamInvitePassword(details) {
    return await axiosInstance.post(`auth/invite-user/create-password`, details);
  }
}
export default new AuthService();
