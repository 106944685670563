<template>
  <div :class="['rounded-full overflow-hidden', size]">
    <img :src="logo_url" :alt="name" :class="['rounded-full block h-auto object-cover', lightBgStyle]" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo_url: null,
    };
  },
  props: {
    username: {
      type: String,
      default: null,
    },
    api: {
      type: String,
      default: 'api/avataaars',
    },
    size: {
      type: String,
      default: 'w-full max-w-full',
    },
  },

  computed: {
    lightBgStyle() {
      return this.$store.state.lightBgStyle;
    },
    // avatar() {
    //   return this.username ? `https://avatars.dicebear.com/${this.api}/${this.username.replace(/[^a-z0-9]+/i, '-')}.svg` : this.logo_url;
    // },
    name() {
      return this.$store.state.auth.userDetails.name;
    },
  },
  async mounted() {
    const user = await this.$store.getters['auth/userDetails'];
    this.logo_url = user.employer_user?.profile_picture || user.employer.logo_url;
  },
};
</script>
