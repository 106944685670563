<template>
  <li class="pl-4">
    <component
      :is="componentIs"
      v-slot="vSlot"
      :to="itemTo"
      :href="itemHref"
      :target="itemTarget"
      class="flex cursor-pointer items-center mb-6"
      @click="menuClick"
    >
      <component
        v-if="item.icon"
        class="w-12 flex-none"
        :class="[vSlot && vSlot.isExactActive ? asideMenuItemActiveStyle : asideMenuItemInactiveStyle]"
        :is="item.icon"
      />
      <span class="grow text-white" :class="[vSlot && vSlot.isExactActive ? asideMenuItemActiveStyle : asideMenuItemInactiveStyle]">{{
        item.label
      }}</span>
      <icon
        v-if="hasDropdown"
        :path="dropdownIcon"
        class="flex-none"
        :class="[vSlot && vSlot.isExactActive ? asideMenuItemActiveStyle : asideMenuItemInactiveStyle]"
        w="w-12"
      />
    </component>
    <aside-menu-list v-if="hasDropdown" :menu="item.menu" is-submenu-list />
  </li>
</template>

<script>
import { mdiMinus, mdiPlus } from '@mdi/js';
import Icon from '@/components/Icon.vue';
import AsideMenuList from '@/components/partials/AsideMenuList.vue';
// const store = useStore()

export default {
  props: {
    item: { type: Object, required: true },
    isSubmenuList: { type: Boolean },
  },
  components: {
    AsideMenuList,
    Icon,
  },
  data() {
    return {
      isDropdownActive: false,
    };
  },
  emit: ['menu-click'],
  computed: {
    asideMenuItemStyle() {
      return this.$store.state.asideMenuItemStyle;
    },
    asideMenuItemActiveStyle() {
      return this.$store.state.asideMenuItemActiveStyle;
    },
    asideMenuItemInactiveStyle() {
      return this.$store.state.asideMenuItemInactiveStyle;
    },
    asideSubmenuListStyle() {
      return this.$store.state.asideSubmenuListStyle;
    },
    componentIs() {
      return this.item.to ? 'router-link' : 'a';
    },
    hasDropdown() {
      return !!this.item.menu;
    },
    dropdownIcon() {
      return this.isDropdownActive ? mdiMinus : mdiPlus;
    },
    itemTo() {
      return this.item.to || null;
    },
    itemHref() {
      return this.item.href || null;
    },
    itemTarget() {
      return this.componentIs === 'a' && this.item.target ? this.item.target : null;
    },
  },
  methods: {
    menuClick(event) {
      this.$emit('menu-click', event, this.item);

      if (this.hasDropdown) {
        this.isDropdownActive = !this.isDropdownActive;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  * {
    color: #ADADAD !important;
  }

  svg::v-deep {
    path {
      stroke: #666666;
      fill: none;
    }
  }

  &.active {
    background-color: transparent !important;
    padding: 0 !important;
    border-radius: 0 !important;

    * {
      color: aquamarine !important;
    }

    svg::v-deep {
      path {
        stroke: aquamarine !important;
      }
    }
  }
}
</style>
